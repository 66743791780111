import React, { useState } from "react";

const Projects = ({ resumeData }) => {
	const { projects } = resumeData;

	// State for the currently active tab
	const [activeTab, setActiveTab] = useState(0);

	return (
		<section id="projects">
			<div className="row projects section-content-spacer">
				{/* Left Column */}
				<div className="three columns header-col">
					<h2>Projects</h2>
					<p>Some of my most recent work.</p>
				</div>

				{/* Right Column with Sliding Tabs */}
				<div className="twelve columns main-col projects">
					<div className="vertical-tabs-container" style={{ display: "flex" }}>
						{/* Tab Buttons (Project Titles) */}
						<div
							className="tabs-list"
							style={{
								flex: "0 0 200px",
								borderRight: "1px solid #ccc",
								marginRight: "1rem",
							}}
						>
							{projects &&
								projects.map((item, i) => (
									<button
										key={i}
										className={`tab-button ${activeTab === i ? "active" : ""}`}
										onClick={() => setActiveTab(i)}
									>
										{item.tab}
									</button>
								))}
						</div>

						{/* Sliding Tab Content */}
						<div
							className="tab-content-container"
							style={{
								flex: 1,
								position: "relative",
								overflow: "hidden",
							}}
						>
							<div
								className="tab-content-slider"
								style={{
									display: "flex",
									transition: "transform 0.5s ease-in-out",
									transform: `translateX(-${activeTab * 100}%)`,
								}}
							>
								{projects &&
									projects.map((item, i) => {
										const {name, description, link, image, tags} = item;
										return (
										<div
											key={i}
											className="tab-panel"
											style={{
												flex: "0 0 100%",
												padding: "0 1rem",
												boxSizing: "border-box",
											}}
										>
											<h3>{name}</h3>
											<p>{description} {link && <a href={link} className="see-more" target="_blank" rel="noopener noreferrer">See page</a>}</p>
											<p className="tags">{tags.map((tag, i) => <span key={i}>{tag}</span>)}</p>
											<img src={image} alt=""></img>
										</div>
									)})}
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Projects;
