let resumeData = {
	imagebaseurl: "",
	name: "Matt Alter",
	role: "Frontend Developer",
	linkedinId: "https://www.linkedin.com/in/mattalter/",
	whoami:
		"I’m a Principal Front End Developer specializing in JavaScript (React), CSS (SCSS, SASS, JSS), and HTML5.",
	whatdoido:
		"As a Front End Developer, I collaborate with designers and engineers to create innovative solutions to design and engineering problems. That means identifying the right tools and technologies to address the problem(s) at hand, while keeping an eye on the future and accounting for any possible accessibility issues.",
	whydoidoit:
		"I love bridging design and engineering, building and prototyping cool things to solve interesting problems!",
	address: "New York",
	education: [{}],
	work: [
		{
			CompanyName: "Oracle",
			logo:
				"./images/oracle.svg",
			link: "https://www.oracle.com/"
		},
		{
			CompanyName: "J.P.Morgan Chase",
			logo:
				"./images/logos-cb-jpm.svg",
			link:
				"https://www.jpmorgan.com/insights/technology/digital-experience-design"
		},
		{
			CompanyName: "AMC Networks",
			logo:
				"./images/amc.svg",
			link: "https://www.sundancenow.com/"
		},
		{
			CompanyName: "E*Trade",
			logo:
				"./images/etrade.svg",
			link: "https://us.etrade.com/home"
		},
		{
			CompanyName: "Thompson Reuters",
			logo:
				"./images/thomsonreuters.svg",
			link: "https://www.reuters.com/video/"
		}
	],
	skillsDescription: "Your skills here",
	skills: [
		{
			skillname: "HTML5"
		},
		{
			skillname: "CSS"
		},
		{
			skillname: "Reactjs"
		}
	],
	projects: [
		{
			name: "Oracle - Contracts Search",
			description: "Leveraging a REST API and in collaboration with multiple cross-functional stakeholders, I built a responsive and intuitive interface enabling users to filter public Oracle contracts based on multiple parameters.",
			tab: "Contracts Search",
			image: "../images/contracts-filtered.png",
			tags: ["JavaScript", "SCSS", "REST API"]
		},
		{
			name: "Oracle - Contracts Sort",
			description: "Leveraging a REST API and in collaboration with multiple cross-functional stakeholders, I built a responsive and intuitive interface of sorted contracts, enabling users to quickly find their data based on either country or contract title.",
			tab: "Contracts Sort",
			image: "../images/contracts-sorted.png",
			tags: ["JavaScript", "SCSS", "REST API"]
		},
		{
			name: "Oracle - Solutions hub",
			description: "I was tasked with creating a Web Component to leverage our API to provide users a searchable and filterable resource of Oracle contracts. This Web Component uses a REST API to retrieve the most up-to-date data.",
			tab: "Solutions Hub",
			image: "../images/solutions-hub.png",
			link: "https://www.oracle.com/cloud/solutions/",
			tags: ["Web Component", "JavaScript", "CSS", "REST API"]
		},
		{
			name: "Oracle - Country Selector",
			description: "Working with multidisciplinary teams, I created a responsive country selector that uses geolocation to display the user's country, language, and page link, enabling them to access the appropriate page based on their location.",
			tab: "Global Country Selector",
			image: "../images/country-selector.png",
			link: "https://www.oracle.com",
			tags: ["JavaScript", "SCSS", "JSON"]
		}
	],
	testimonials: [
		{
			description: "",
			name: ""
		}
	],
	contact: [
		{
			link: "http://www.linkedin.com/in/mattalter",
			logo: "../images/linkedin.png"
		},
		// {
		// 	link: "https://github.com/mattography",
		// 	logo: "../images/github.png"
		// }
	]
};

export default resumeData;
