import React, { useState, useEffect } from "react";

const Header = ({ resumeData }) => {
	const [isActive, setActive] = useState("");

	useEffect(() => {
		const sections = document.querySelectorAll("header, section");

		const observerOptions = {
			root: null,
			rootMargin: "0px",
			threshold: 0.5,
		};

		const observer = new IntersectionObserver((entries) => {
			let contactInView = false;

			entries.forEach((entry) => {
				if (entry.target.id === "contact" && entry.isIntersecting) {
					contactInView = true;
					setActive("#contact");
				}
			});

			if (!contactInView) {
				const visibleEntries = entries.filter((entry) => entry.isIntersecting);
				if (visibleEntries.length > 0) {
					const closestEntry = visibleEntries.reduce((closest, entry) => {
						return entry.boundingClientRect.top < closest.boundingClientRect.top
							? entry
							: closest;
					});
					setActive(`#${closestEntry.target.id}`);
				}
			}
		}, observerOptions);

		sections.forEach((section) => observer.observe(section));

		return () => observer.disconnect();
	}, []);

	const toggleClass = (e) => {
		setActive(e.currentTarget.hash);
	};

	return (
		<React.Fragment>
			<nav id="nav-wrap">
				<a className="mobile-btn rotate-90-cw" href="#nav-wrap" title="Show navigation">
					Show navigation
				</a>
				<a className="mobile-btn rotate-90-cw" href="/#" title="Hide navigation">
					Hide navigation
				</a>
				<ul id="nav" className="nav fade-in-top">
					<li className={isActive === "#home" ? "current" : ""}>
						<a href="#home" onClick={toggleClass}>
							Home
						</a>
					</li>
					<li className={isActive === "#about" ? "current" : ""}>
						<a href="#about" onClick={toggleClass}>
							About
						</a>
					</li>
					<li className={isActive === "#experience" ? "current" : ""}>
						<a href="#experience" onClick={toggleClass}>
							Experience
						</a>
					</li>
					<li className={isActive === "#projects" ? "current" : ""}>
						<a href="#projects" onClick={toggleClass}>
							Projects
						</a>
					</li>
					<li className={isActive === "#contact" ? "current" : ""}>
						<a href="#contact" onClick={toggleClass}>
							Contact
						</a>
					</li>
				</ul>
			</nav>
			<div className="header-overlay"></div>
			<header id="home">
				<div className="banner">
					<div className="banner-text">
						<h1 className="responsive-headline">Hi, I'm Matt Alter.</h1>
						<p style={{ color: "#fff", fontFamily: "opensans-regular" }}>
							Scroll down to find out more.
						</p>
					</div>
				</div>
				<p className="scrolldown">
					<a className="smoothscroll" href="#about">
						<i className="icon-down-circle"></i>
						<span style={{ opacity: "0" }}>scroll</span>
					</a>
				</p>
			</header>
		</React.Fragment>
	);
};

export default Header;
